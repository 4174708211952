<template>
  <template v-if="isLoading" />
  <template v-else-if="isTokenValid">
    <div class="login__form-title">
      Установите пароль
    </div>
    <change-password-form :action="register" :token="token" />
  </template>

  <div class="login__copy">
    <router-link class="login__copy-text" to="/policy">
      Политика конфиденциальности
    </router-link>
    <div class="login__signature">
      ©©{{ currentYear }}, «DSF Trading Co», «DSF Trading Co»
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from '@/components/auth/ChangePasswordForm.vue'
import { useApi } from '@/api'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  components: {
    ChangePasswordForm
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const isLoading = ref(!!props.token)
    const isTokenValid = ref(false)
    const api = useApi()
    const router = useRouter()
    const toast = useToast()
    const currentYear = ref((new Date()).getFullYear())
    api.auth.checkRegisterToken(props.token).then(() => {
      isTokenValid.value = true
    }).catch(() => {
      isTokenValid.value = false
      toast.error('Недействительная ссылка')
      router.push('/login')
    }).finally(() => {
      isLoading.value = false
    })
    const register = api.auth.register.bind(api.auth)
    return {
      register,
      isTokenValid,
      isLoading,
      currentYear
    }
  }
}
</script>
